import client, {
  endpoints,
  DEFAULT_BUCKET
} from './kinto.service'
import {
  qsify
} from 'kinto-http/lib/utils'

class AccountsService {
  login (email, password, auth_method) {
    const path = endpoints.jwt_token()
    return client.execute({
      path,
      body: {
        email,
        password,
        auth_method
      },
      method: 'POST'
    })
  }

  logout () {

  }

  openid_login (token) {
    const path = endpoints.openid_jwt_token()
    return client.execute({
      path,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` }
    })
  }

  async list (qfilters) {
    let path = '/accounts'
    if (qfilters) {
      path += path.indexOf('?') === -1 ? '?' : '&'
      path += qsify(qfilters)
    }
    let accounts = await client.execute({
      path,
      headers: client._headers
    })
    accounts = accounts.data
    let profiles = await client.bucket(DEFAULT_BUCKET).collection('profiles').listRecords()
    profiles = profiles.data
    const merged = accounts.map(account => ({
      ...profiles.find((profile) => (account.id === profile.id) && profile),
      ...account
    }))
    return merged
  }

  get (record) {
    const path = `/accounts/${record}`
    return client.execute({
      path,
      headers: client._headers
    })
  }

  add (data) {
    const path = '/accounts'
    return client.execute({
      path,
      body: {
        data
      },
      method: 'POST',
      headers: client._headers
    })
  }

  update (record, data) {
    const path = `/accounts/${record}`
    return client.execute({
      path,
      body: {
        data
      },
      method: 'PUT',
      headers: client._headers
    })
  }

  patch (record, data) {
    const path = `/accounts/${record}`
    return client.execute({
      path,
      body: {
        data
      },
      method: 'PATCH',
      headers: client._headers
    })
  }

  delete (record) {
    const path = `/accounts/${record}`
    return client.execute({
      path,
      method: 'DELETE',
      headers: client._headers
    })
  }

  // NOTE: returns a few profiles with a few fields
  get_profiles (ids = [], fields = ['first_name', 'last_name', 'email2']) {
    const filters = (ids.length > 0) ? { in_id: ids.join(',') } : undefined
    return client.bucket(DEFAULT_BUCKET).collection('profiles').listRecords({
      filters: filters,
      fields: fields
    })
  }

  get_fullprofiles (skip_inactive = false, in_ids = [], exclude_ids = []) {
    // return profiles with accounts that works with non-admin users
    let path = endpoints.accounts_info()
    return client.execute({
      path,
      body: {
        in_ids,
        exclude_ids,
        skip_inactive
      },
      method: 'POST',
      headers: client._headers
    })
  }

  // profile
  get_profile (id) {
    return client.bucket(DEFAULT_BUCKET).collection('profiles').getRecord(id)
  }

  update_profile (id, data) {
    // NOTE: workaround for removed fields
    // TODO: move it to views
    const excludeFields = ['title', 'office_phone', 'biography', 'mobile']
    excludeFields.forEach(function (field) {
      if (field in data) {
        delete data[field]
      }
    })
    return client.bucket(DEFAULT_BUCKET).collection('profiles').createRecord({
      ...data,
      id
    })
  }

  // settings
  get_settings (id) {
    return client.bucket(DEFAULT_BUCKET).collection('settings').getRecord(id)
  }

  update_settings (id, data) {
    return client.bucket(DEFAULT_BUCKET).collection('settings').createRecord({
      ...data,
      id
    })
  }

  async history (filters = {}) {
    let history = await client.bucket(DEFAULT_BUCKET).listHistory({ filters })
    history = history.data
    const accounts = await this.list()
    const merged = history.map(h => ({
      ...{ user_profile: accounts.find((profile) => (h.user_id === 'jwt:' + profile.id) && profile) },
      ...h
    }))
    return merged
  }
}

export default new AccountsService()
