<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = to.meta.label ? `${to.meta.label} | QuickOASIS` : 'QuickOASIS'
      }
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
