import NotificationService from '@/services/notification.service'

const state = {
  checkedAt: localStorage.getItem('notificationCheckedAt') ?? 0,
  items: [],
  count: 0,
  total: 0,
  _running: false
}
const INTERVAL = 10 * 1000

const mutations = {
  setItems (state, value) {
    state.items = value
  },
  setCount (state, value) {
    state.count = value
  },
  setTotal (state, value) {
    state.total = value
  },
  setCheckedAt (state) {
    const now = Date.now()
    state.checkedAt = now
    localStorage.setItem('notificationCheckedAt', now)
  },
  run (state) {
    state._running = true
  }
}

const actions = {
  run ({ state, commit }) {
    if (!state._running) {
      commit('run')
      setInterval(() => {
        // let delta = Date.now() - state.checkedAt
        if (document.hasFocus()) {
          console.debug('checking notify ...')
          NotificationService.count().then((total) => {
            commit('setTotal', total)
          })
          NotificationService.listUnread().then((resp) => {
            commit('setItems', resp.data)
            commit('setCount', resp.data.length)
          }).catch((error) => { })
          commit('setCheckedAt')
        }
      }, INTERVAL)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
