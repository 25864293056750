import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import auth from './modules/auth'
import sidebar from './modules/sidebar'
import breadcrumbs from './modules/breadcrumbs'
import notification from './modules/notification'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    sidebar,
    breadcrumbs,
    notification
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
