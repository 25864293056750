import KintoClient from 'kinto-http'

export const DEFAULT_BUCKET = 'oasis'
export const NOTIFICATION_BUCKET = 'notifications'

const client = new KintoClient(process.env.VUE_APP_API_URL)
const token = localStorage.getItem('token') ?? null
if (token) { client.setHeaders({ Authorization: `JWT ${token}` }) }

export const endpoints = {
  ...client.endpoints,
  globalsettings: (id) =>
    '/buckets/oasis/collections/globalsettings/records' + (id ? `/${id}` : ''),
  contenttypes: (id) =>
    '/buckets/oasis/contenttypes' + (id ? `/${id}` : ''),
  comments: (id) =>
    '/buckets/oasis/comments' + (id ? `/${id}` : ''),
  acknowledgments: (id) =>
    '/buckets/oasis/collections/acknowledgments/records' + (id ? `/${id}` : ''),
  jwt_token: () => '/jwt/token',
  openid_jwt_token: () => '/jwt/openid_token',
  counter: () => '/counter',
  stats: () => '/stats',
  accounts_info: () => '/accounts_info',
  usermanual_info: (id) => `/contenttypes/usermanualinfo/${id}`,
  share: (id) => `/contenttypes/share/${id}`,
  notify: (id) => `/contenttypes/notify/${id}`,
  wysiwyg: (id) => `/buckets/wysiwyg/collections/${id}/records`
}

export default client
