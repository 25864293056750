import AccountsService from '@/services/accounts.service'
import client from '@/services/kinto.service'

const superuserGroupUri = '/buckets/oasis/groups/admins'

const state = {
  userId: localStorage.getItem('userId') ?? null,
  token: localStorage.getItem('token') ?? null,
  email: localStorage.getItem('email') ?? null,
  principals: [],
  isSuperAdmin: false,
  orgs: { member: [], admin: [] }
}

const getters = {
  is_authenticated: state => !!state.token,
}

const actions = {
  async login ({ commit, dispatch }, { email, password, auth_method }) {
    const response = await AccountsService.login(email, password, auth_method)
    const token = response.token
    const userId = response.user_id
    commit('login', { token, email, userId })
    client.setHeaders({ Authorization: `JWT ${token}` })
    await dispatch('updatePrincipals')
  },
  async openid_login ({ commit }, { openid_token }) {
    const response = await AccountsService.openid_login(openid_token)
    const token = response.token
    const userId = response.user_id
    const email = response.email
    commit('login', { token, email, userId })
    client.setHeaders({ Authorization: `JWT ${token}` })
  },
  async updatePrincipals ({ commit }) {
    const response = await client.fetchUser()
    const principals = response.principals
    commit('updatePrincipals', { principals })
  },
  logout ({
    commit
  }) {
    commit('logout')
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    localStorage.removeItem('userId')
    AccountsService.logout()
  }
}

const mutations = {
  login (state, { token, email, userId }) {
    localStorage.setItem('token', token)
    localStorage.setItem('email', email)
    localStorage.setItem('userId', userId)
    state.token = token
    state.email = email
    state.userId = userId
  },
  updatePrincipals (state, { principals }) {
    state.principals = principals
    state.isSuperAdmin = principals.includes(superuserGroupUri)

    const orgs = { member: [], admin: [] }
    orgs.member = principals.filter(item => item.endsWith('_members')).map(
      item =>
        item.slice(22, -8) // converting /buckets/oasis/groups/b87043b2-e8ec-4900-9a39-892f30a212e1_members to b87043b2-e8ec-4900-9a39-892f30a212e1
    )
    orgs.admin = principals.filter(item => item.endsWith('_admins')).map(
      item =>
        item.slice(22, -7)
    )
    state.orgs = orgs
  },
  logout (state) {
    state.userId = null
    state.email = null
    state.token = null
    state.principals = []
    state.isSuperAdmin = false
    state.orgs = { member: [], admin: [] }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
