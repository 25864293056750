import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

// Containers
const QContainer = () => import('@/containers/QContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const GlobalSettings = () => import('@/views/settings/GlobalSettings')
const AllAcknowledgments = () => import('@/views/settings/AllAcknowledgments')

// Views - Pages
const Page403 = () => import('@/views/pages/Page403')
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')

const Search = () => import('@/views/pages/Search')
const ImagesManager = () => import('@/views/pages/ImagesManager')
const Tags = () => import('@/views/pages/Tags')

// Users
const Accounts = () => import('@/views/accounts/Accounts')
const EditAccount = () => import('@/views/accounts/EditAccount')
const AddAccount = () => import('@/views/accounts/AddAccount')
const ViewAccount = () => import('@/views/accounts/ViewAccount')
const ChangeAccountPassword = () => import('@/views/accounts/ChangeAccountPassword')
const EditSettings = () => import('@/views/accounts/EditSettings')
const SuperUsers = () => import('@/views/accounts/SuperUsers')

const Activity = () => import('@/views/accounts/Activity')
const Revert = () => import('@/views/accounts/Revert')
const QLog404ErrorsList = () => import('@/views/accounts/Log404ErrorsList')

// Permissions
const ContentTypeMembers = () => import('@/views/contenttypes/ContentTypeMembers')

// Views - Auth
const Login = () => import('@/views/auth/Login')
const OpenIDToken = () => import('@/views/auth/OpenIDToken')

// Oasis

// const ContentTypes = () => import('@/views/contenttypes/ContentTypes')
const AddContentType = () => import('@/views/contenttypes/AddContentType')
const EditContentType = () => import('@/views/contenttypes/EditContentType')
// const ViewContentType = () => import('@/views/contenttypes/ViewContentType')
const ViewDynamicItem = () => import('@/views/contenttypes/ViewDynamicItem')
const MyContent = () => import('@/views/contenttypes/MyContent')
const MyOrgUnits = () => import('@/views/contenttypes/MyOrgUnits')
const MyFavorites = () => import('@/views/contenttypes/MyFavorites')
const QNotificationList = () => import('@/views/accounts/NotificationList')
const PersonalAcknowledgements = () => import('@/views/contenttypes/PersonalAcknowledgements')

const RecentChanges = () => import('@/views/contenttypes/RecentChanges')
const ListFacilities = () => import('@/views/contenttypes/ListFacilities')
const ListDocuments = () => import('@/views/contenttypes/ListDocuments')

Vue.use(Router)

const router = new Router({
  mode: 'history', // 'hash' https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: configRoutes()
})

function configRoutes () {
  return [{
    path: '/auth',
    redirect: '/pages/404',
    name: 'Auth',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: 'login',
      name: 'Login',
      component: Login
    },
    {
      path: 'openid/:openid_data',
      name: 'openidtoken',
      component: OpenIDToken,
      props: true
    }
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '403',
        name: 'Page403',
        component: Page403
      },
      {
        path: '404',
        name: 'Page404',
        component: Page404
      },
      {
        path: '500',
        name: 'Page500',
        component: Page500
      },
      {
        path: 'imagesmanager',
        name: 'ImagesManager',
        component: ImagesManager
      }
    ]
  },
  {
    path: '/',
    component: QContainer,
    meta: {
      login_required: true
    },
    children: [{
      path: '/',
      redirect: '/quick',
      name: 'home'
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        label: 'Recent Changes'
      }
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: {
        label: 'Search'
      }
    },
    {
      path: '/tags',
      name: 'tags',
      component: Tags,
      meta: {
        label: 'Tags'
      }
    },
    {
      path: '/globalsettings',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'globalsettings',
          component: GlobalSettings,
          meta: {
            label: 'Global Settings'
          }
        },
        {
          path: 'acknowledgments',
          name: 'allacknowledgments',
          component: AllAcknowledgments,
          meta: {
            label: 'All acknowledgments'
          }
        }
      ]
    },
    // Oasis
    {
      path: '/dashboard',
      name: 'dashboard:index',
      meta: {
        label: 'Dashboard'
      },
      component: RecentChanges
    },

    {
      path: '/users',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [{
        path: '',
        name: 'accounts',
        component: Accounts,
        meta: {
          label: 'Users'
        },
        props: true
      },
      {
        path: '@add',
        name: 'accounts:add',
        component: AddAccount,
        meta: {
          label: 'Add User'
        }
      },
      {
        path: '@superusers',
        name: 'accounts:superusers',
        component: SuperUsers,
        meta: {
          label: 'Super Users'
        }
      },
      {
        path: ':accountId/@edit',
        name: 'accounts:edit',
        component: EditAccount,
        props: true,
        meta: {
          label: 'Edit User'
        }
      },
      {
        path: ':accountId/@view',
        name: 'accounts:view',
        component: ViewAccount,
        props: true,
        meta: {
          label: 'User\'s View'
        }
      },
      {
        path: ':accountId/@pwd',
        name: 'accounts:password',
        component: ChangeAccountPassword,
        props: true,
        meta: {
          label: 'User\'s Password Change'
        }
      },
      {
        path: ':accountId/@settings',
        name: 'accounts:settings',
        component: EditSettings,
        props: true,
        meta: {
          label: 'User\'s Settings'
        }
      },
      {
        path: ':accountId/@personal-acknowledgements',
        name: 'accounts:acknowledgements',
        component: PersonalAcknowledgements,
        props: true,
        meta: {
          label: 'Personal Acknowledgements'
        }
      }
      ]
    },
    {
      path: '@activity',
      name: 'activity',
      component: Activity,
      meta: {
        label: 'Users Activity'
      }
    },
    {
      path: '@activity/user/:accountId/',
      name: 'activity:user',
      component: Activity,
      props: true,
      meta: {
        label: 'User\'s Activity'
      }
    },
    {
      path: '@activity/ct/:contenttypeId/',
      name: 'activity:contenttype',
      component: Activity,
      props: true,
      meta: {
        label: 'Content Activity'
      }
    },
    {
      path: '@activity/log404errors/',
      name: 'log:404errors',
      component: QLog404ErrorsList,
      props: false,
      meta: {
        label: 'Log 404 Errors'
      }
    },
    {
      path: '@activity/revision/:revisionId/',
      name: 'activity:revert',
      component: Revert,
      props: true,
      meta: {
        label: 'Revert revision'
      }
    },
    {
      path: '*/@edit',
      name: 'contenttype:edit',
      component: EditContentType
    },
    {
      path: '*/@listing',
      name: 'contenttype:listing',
      component: ViewDynamicItem
    },
    {
      path: '*/@add/:contenttype',
      name: 'contenttype:subadd',
      meta: {
        label: 'Add Subitem'
      },
      component: AddContentType,
      props: true,
      beforeEnter: (to, from, next) => {
        // validate contenttype value in URL
        const allowedContenttypes = ['org', 'folder', 'file', 'webpage', 'podcast', 'video', 'photo', 'image', 'facility', 'newsfolder', 'newspage', 'blog', 'blogpost', 'announcement', 'isodocument', 'usermanual', 'usermanualpage']
        if (allowedContenttypes.includes(to.params.contenttype)) {
          next()
        } else {
          next({
            name: 'Page404',
            params: {
              previousUrl: to.fullPath,
              issue: 'not allowed content type'
            }
          })
        }
      }
    },
    {
      path: '*/@members',
      name: 'contenttype:members',
      props: true,
      meta: {
        label: 'Members'
      },
      component: ContentTypeMembers
    },

    {
      path: '*/@facilities',
      name: 'contenttype:facilities',
      meta: {
        label: 'Facilities'
      },
      component: ListFacilities
    },
    {
      path: '*/@documents',
      name: 'contenttype:documents',
      meta: {
        label: 'Documents'
      },
      component: ListDocuments
    },
    {
      path: '/@mycontent',
      name: 'contenttype:my',
      component: MyContent,
      meta: {
        label: 'My Content'
      }
    },
    {
      path: '/@myorgunits',
      name: 'contenttype:myorgs',
      component: MyOrgUnits,
      meta: {
        label: 'My Org Units'
      }
    },
    {
      path: '/@myfavorites',
      name: 'contenttype:myfavorites',
      component: MyFavorites,
      meta: {
        label: 'My Favorites'
      }
    },
    {
      path: '/@notifications',
      name: 'notifications',
      component: QNotificationList,
      meta: {
        label: 'Notifications'
      },
      props: (route) => ({ filters: { type: route.query.type, read: route.query.read } })
    },
    {
      path: '@recentchanges',
      name: 'contenttype:recent-changes',
      component: RecentChanges,
      meta: {
        label: 'Recent Changes'
      },
      props: true
    },
    {
      path: '*',
      name: 'contenttype:view',
      component: ViewDynamicItem,
      props: true
    }
    ]
  },
  {
    path: '*',
    redirect: '/pages/404'
  }
  ]
}

router.beforeEach((to, from, next) => {
  store.commit('breadcrumbs/clear') // clear breadcrumb when url changes
  if (to.matched.some(record => record.meta.login_required)) {
    if (store.getters['auth/is_authenticated']) {
      next()
      return
    }
    // console.log('login!')
    next({
      name: 'Login',
      params: {
        nextUrl: to.fullPath
      }
    })
  } else {
    next()
  }
})

export default router
