import {
  cibApplePodcasts,
  cibMicrosoft,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilArrowLeft,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilBrowser,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCamera,
  cilClipboard,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCaretRight,
  cilCaretBottom,
  cilCursor,
  cilDescription,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilExternalLink,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFolder,
  cilHeart,
  cilHistory,
  cilImage,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMinus,
  cilMoon,
  cilMonitor,
  cilNewspaper,
  cilOptions,
  cibOpenAccess,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPlus,
  cilPuzzle,
  cilReload,
  cilRss,
  cilSearch,
  cilSettings,
  cilShare,
  cilShareAlt,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTask,
  cilTag,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilVideo,
  cilWarning,
  cilXCircle
} from '@coreui/icons'

import { logo, logosmall } from './logo'

export const iconsSet = Object.assign({}, { logo, logosmall }, {
  cilArrowLeft,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilBrowser,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCamera,
  cilClipboard,
  cilCloudDownload,
  cilCaretRight,
  cilCaretBottom,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDescription,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilExternalLink,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFolder,
  cilHeart,
  cilHistory,
  cilImage,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMinus,
  cilMoon,
  cilMonitor,
  cilNewspaper,
  cilOptions,
  cibOpenAccess,
  cilPencil,
  cilPhone,
  cilPeople,
  cilPlus,
  cilPuzzle,
  cilReload,
  cilRss,
  cilSearch,
  cilSettings,
  cilShare,
  cilShareAlt,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTask,
  cilTag,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilVideo,
  cilWarning,
  cilXCircle
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibApplePodcasts,
  cibMicrosoft
})
