import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {
  iconsSet as icons
} from './assets/icons/icons.js'
import VueMoment from 'vue-moment'
// import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import VueCookies from 'vue-cookies'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import { abilitiesPlugin } from '@casl/vue'
import { Ability } from '@casl/ability'
import store from './store'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueCookies)
Vue.use(VueMoment)
Vue.use(DateRangePicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDSmnz2WneRtPl4aereUcK3AjgdMmet8fs', // TODO: move to Settings
    libraries: 'geocoder'
  },
  installComponents: true
})
const ability = new Ability()
Vue.use(abilitiesPlugin, ability)

// Vue global filters
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('prettyBytes', function (num, precision) {
  // usage: {{ file.size | prettyBytes }}
  num = Number(num)
  if (isNaN(num)) {
    throw new TypeError('Expected a number')
  }
  if (isNaN(precision) || (precision === undefined || precision == null)) {
    precision = 3
  }
  const neg = num < 0
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  if (neg) {
    num = -num
  }
  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }
  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
  num = (num / Math.pow(1000, exponent)).toPrecision(precision) * 1
  const unit = units[exponent]
  return (neg ? '-' : '') + num + ' ' + unit
})
Vue.filter('getDocID', function (vnumber, revision) {
  if (vnumber) return vnumber
  else if (revision) return revision
  else return 0
})
Vue.filter('pluralize', function (amount, singular, plural = null) {
  if (plural === null) {
    plural = `${singular}s`
  }
  return (amount > 1 || amount === 0) ? plural : singular
})
Vue.filter('possessive', function (value) {
  if (!value) return ''
  value = value.toString()
  if (['s', 'x'].includes(value.slice(-1))) {
    return value + '\''
  } else {
    return value + '\'s'
  }
})
// Vue.component('v-select', vSelect)

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  icons,
  template: '<App/>',
  store,

  components: {
    App
  }
})
