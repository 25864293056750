import client, {
  NOTIFICATION_BUCKET
} from './kinto.service'
import store from '@/store'

class NotificationService {
  list (qfilter) {
    return client.bucket(NOTIFICATION_BUCKET).collection(store.state.auth.userId).listRecords(qfilter)
  }

  listUnread () {
    return client.bucket(NOTIFICATION_BUCKET).collection(store.state.auth.userId).listRecords({ filters: { read: false } })
  }

  delete (recordId) {
    return client.bucket(NOTIFICATION_BUCKET).collection(store.state.auth.userId).deleteRecord(recordId)
  }

  count () {
    return client.bucket(NOTIFICATION_BUCKET).collection(store.state.auth.userId).getTotalRecords()
  }

  read (recordId) {
    const updateData = { id: recordId, read: true }
    return client.bucket(NOTIFICATION_BUCKET).collection(store.state.auth.userId).updateRecord(updateData, { patch: true })
  }
}

export default new NotificationService()
