import { lpathToPath } from '@/router/utils'

const state = {
  _items: []
}

const mutations = {
  set (state, value) {
    state._items = value
  },
  clear (state) {
    state._items = []
  }
}
const getters = {
  items () {
    const result = []
    for (const i of state._items) {
      let item = {}
      if (i.to) {
        item =
          {
            text: i.name,
            id: i.id,
            to: i.to
          }
      } else if (i.path) {
        item = {
          text: i.name,
          id: i.id,
          to: {
            name: 'contenttype:view',
            params: {
              pathMatch: lpathToPath(i.path)
            }
          }
        }
      } else {
        item = { text: i.name }
      }
      result.push(item)
    }
    return result
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  getters
}
