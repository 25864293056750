export function pathToLpath (path) {
  // Convert URL path to Ltree
  return path.replace(/\/\//g, '/') // replace double slashes with single ones
    .replace(/^\/|\/$/g, '') // remove slashes at the beginning and end
    .replace(/\//g, '.') // replace / to .
}

export function lpathToPath (lpath) {
  const path = lpath.replace(/\./g, '/')
  return `${path}`
}
